import * as bootstrap from 'bootstrap'

/**
 * - ADdress
 * - Verfügbarkeit
 * - POP
 * - Ready Date / Bestellbar ab. -> GK1/11
 * - Quality
 *  - G1,GK11 -> Bestellbar ab
 *  - G2,GK12 -> Mutmasslich bestellbar ab
 *  - G3,GK13 -> Voraussichtlich bestellbar ab
 *  - G4,GK14 -> Vermutlich bestellbar ab
 *
 *  => Datumsverbindlichkeit 90%/70%/50%/30%
 *
 */


/*
Object Data:

{
  "geometry": {
    "disposed": false,
    "pendingRemovals_": null,
    "dispatching_": null,
    "listeners_": {
      "change": [
        null
      ]
    },
    "revision_": 1,
    "ol_uid": "724",
    "values_": null,
    "extent_": [
      1000491.953976,
      5900562.539075,
      1000491.953976,
      5900562.539075
    ],
    "extentRevision_": 1,
    "simplifiedGeometryMaxMinSquaredTolerance": 0,
    "simplifiedGeometryRevision": 0,
    "layout": "XY",
    "stride": 2,
    "flatCoordinates": [
      1000491.953976,
      5900562.539075
    ]
  },
  "id": "RFO4180",
  "nc_sad_device_loc": "TRU",
  "nc_sad_netw_code": "TRU",
  "nc_sad_netw_loc_code": "660",
  "priority": 3,
  "sad_alo_ind": false,
  "sad_city_name": "Trun",
  "sad_com_dt": null,
  "sad_gwr_building_egid": 191971881,
  "sad_house_num": "20",
  "sad_isr_dt": "2023-06-29",
  "sad_municipality_name": "Trun",
  "sad_postal_code": 7166,
  "sad_residence_name": null,
  "sad_street_name": "Splecs, via",
  "sad_target_dt": null,
  "sad_target_dt_quality_id": null,
  "scs_site_name": "660TRU",
  "site_id": null,
  "site_name": null,
  "site_service_start": null,
  "site_status": null,
  "source": "ready-for-order",
  "state": "h7_rfo",
  "hover": 1,
  "clicked": 1
}
 */



function formatDateYMD(date) {
    if (!date) {
        return ''
    }
    return date.toISOString().substring(0, 10)
}

class PointData {
    constructor(feature) {
        this.egaid = feature.get('egaid')
        this.egid = feature.get('egid')
        this.building_status = feature.get('building_status')
        this.construction_year = feature.get('construction_year')
        this.dwelling_count = feature.get('dwelling_count')
        this.street_name = feature.get('street_name')
        this.house_number = feature.get('house_number')
        this.building_name = feature.get('building_name')
        this.locality = feature.get('locality')
        this.postal_code = feature.get('postal_code')
        this.access_provider = feature.get('access_provider')
        this.access_provider_pop = feature.get('access_provider_pop')
        this.init7_pop = feature.get('init7_pop')
        this.topology = feature.get('topology')
        this.is_ready = feature.get('is_ready')
        this.planned_date = feature.get('planned_date')
        this.planned_date_confidence = feature.get('planned_date_confidence')
        this.constructed_date = feature.get('constructed_date')
        this.com_date = feature.get('com_date')
        this.pick_priority = feature.get('pick_priority')
        this.pop_id = feature.get('pop_id')
        this.pop_status = feature.get('pop_status')
        this.public_pop_name = feature.get('public_pop_name')
        this.available_now = feature.get('available_now')
        this.class = feature.get('class')
        this.render_order = feature.get('render_order')
    }

    getFormattedAddress() {
        return `${this.street_name} ${this.house_number}<br>${this.postal_code} ${this.locality}`
    }

    getFormattedPopName() {
        let pop_name = this.init7_pop || this.access_provider_pop

        if (!pop_name) {
            return 'N/A'
        }

        if (this.public_pop_name) {
            pop_name += ` - ${this.public_pop_name}`
        }
        return pop_name
    }

    isP2P() {
        return this.topology === 'ftth-p2p'
    }

    getTopology() {
        return this.isP2P() ? 'P2P' : 'P2MP'
    }

    hasOrderableProduct() {
        return ['h7_rfo', 'f7_rfo'].includes(this.class) || this.available_now === 'h7_rfo'
    }

    getProduct() {
        let product = PointInfo.productMap[this.class]
        if (['h7_rfo', 'h7_rfm'].includes(this.class)) {
            if (!this.isP2P()) {
                product = PointInfo.productMap[`${this.class}_p2mp`]
            }
        }

        if (['f7_rfm'].includes(this.class) && this.available_now === 'h7_rfo') {
            product = PointInfo.productMap['h7_now']
            if (!this.isP2P()) {
                product = PointInfo.productMap[`h7_now_p2mp`]
            }
        }

        return product
    }

    getTargetDate() {
        if (this.constructed_date) {
            return new Date(this.constructed_date)
        }
        if (this.planned_date) {
            return new Date(this.planned_date)
        }
    }

    getQuality() {
        if (['f7_rfo', 'h7_rfo'].includes(this.class)) {
            return [5, null]
        }

        const targetDate = this.getTargetDate()
        const now = new Date()

        let quality = 0;

        if (this.planned_date_confidence) {
            quality = PointInfo.qualityMapNumeric[this.planned_date_confidence]
        } else if (targetDate && targetDate < now) {
            quality = 5
        }

        if (targetDate && targetDate > now) {
            return [quality, targetDate]
        }
        return [quality, null]
    }

    getOrderURL() {
        const searchParams = new URLSearchParams({
            postal_code: this.postal_code,
            locality: this.locality,
            street_name: this.street_name,
            house_number: this.house_number,
        })

        return `https://www.init7.net/en/resultat-test-fibre-optique?${searchParams.toString()}`
    }
}

export class PointInfo {

    static qualityMap = {
        '1': '90%',
        '2': '70%',
        '3': '50%',
        '4': '30%',
        '11': '90%',
        '12': '70%',
        '13': '50%',
        '14': '30%',
    }

    static qualityMapNumeric = {
        '1': 4,
        '2': 3,
        '3': 2,
        '4': 1,
        '11': 4,
        '12': 3,
        '13': 2,
        '14': 1,
    }

    static productMap = {
        'h7_rfo': '<a href="https://www.init7.net/de/internet/weitere-angebote/" target="_blank" rel="noopener">Hybrid7</a>',
        'h7_rfm': '<a href="https://www.init7.net/de/internet/weitere-angebote/" target="_blank" rel="noopener">Hybrid7</a>',
        'h7_rfo_p2mp': '<a href="https://www.init7.net/de/internet/weitere-angebote/" target="_blank" rel="noopener">Hybrid7 P2MP</a>, <a href="https://www.init7.net/de/internet/easy7/" target="_blank" rel="noopener">Easy7</a>',
        'h7_rfm_p2mp': '<a href="https://www.init7.net/de/internet/weitere-angebote/" target="_blank" rel="noopener">Hybrid7 P2MP</a>, <a href="https://www.init7.net/de/internet/easy7/" target="_blank" rel="noopener">Easy7</a>',
        'f7_rfo': '<a href="https://www.init7.net/de/internet/fiber7/" target="_blank" rel="noopener">Fiber7</a>, <a href="https://www.init7.net/de/internet/easy7/" target="_blank" rel="noopener">Easy7</a>',
        'f7_rfm': '<a href="https://www.init7.net/de/internet/fiber7/" target="_blank" rel="noopener">Fiber7</a>, <a href="https://www.init7.net/de/internet/easy7/" target="_blank" rel="noopener">Easy7</a>',
        'alex_na': 'N/A',
        'h7_now': '<a href="https://www.init7.net/de/internet/weitere-angebote/" target="_blank" rel="noopener">Hybrid7 (now)</a> <br>Fiber7, Easy7 (soon)',
        'h7_now_p2mp': '<a href="https://www.init7.net/de/internet/weitere-angebote/" target="_blank" rel="noopener">Hybrid7 P2MP</a>, <a href="https://www.init7.net/de/internet/easy7/" target="_blank" rel="noopener">Easy7</a> (now) <br>Fiber7 (soon)',
    }

    constructor(rootElement) {
        this.rootElement = rootElement
        this.bsElement = new bootstrap.Collapse(this.rootElement, {toggle: false})

        this._$address = this.rootElement.querySelector('.f-address')
        this._$pop = this.rootElement.querySelector('.f-pop')
        this._$product = this.rootElement.querySelector('.f-product')
        this._$topology = this.rootElement.querySelector('.f-topology')
        this._$readyDate = this.rootElement.querySelector('.f-ready-date')
        this._$quality = this.rootElement.querySelector('.f-quality-rating')
        this._qualityClassName = this._$quality.className
        this._$orderButton = this.rootElement.querySelector('.f-order-btn')

    }

    updateSidebar(feature) {
        if (!feature) {
            this.bsElement.hide()
            return
        }

        const point = new PointData(feature)

        this._$address.innerHTML = point.getFormattedAddress()
        this._$pop.innerHTML = point.getFormattedPopName()
        this._$product.innerHTML = point.getProduct()
        this._$topology.innerHTML = point.getTopology()

        // const featureState = feature.get('state')

        const [quality, targetDate] = point.getQuality()

        this.rootElement.classList.remove('has-ready-date')
        if (targetDate) {
            this._$readyDate.innerHTML = formatDateYMD(targetDate)
            this.rootElement.classList.add('has-ready-date')
        }

        this._$quality.className = this._qualityClassName + ' ' + 'circ-rating-v' + quality

        if (point.hasOrderableProduct()) {
            this._$orderButton.href = point.getOrderURL()
            this._$orderButton.setAttribute('style', '')
        } else {
            this._$orderButton.setAttribute('style', 'display: none')
        }

        this.bsElement.show()
    }

}